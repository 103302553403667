<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <validation-observer ref="addSallingValidation">
      <b-card class="product-add-wrapper p-1">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('lbl_customer_name')"
                rules="required"
              >
                <h5 class for>
                  {{ $t('lbl_customer_name') }}
                  <span class="text-danger">*</span>
                </h5>

                <b-form-input
                  v-model="customerInfo.name"
                  :placeholder="$t('lbl_enter_customer_name')"
                />
                <small class="text-danger">{{ errors[0] ? $t('lbl_enter_customer_name') : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider :name="$t('lbl_phone')">
                <h5 class for>{{$t('lbl_phone')}}</h5>

                <b-form-input
                  type="number"
                  @blur="handleGetCustomerInfo"
                  v-model="customerInfo.phone"
                  :placeholder="$t('lbl_enter_phone')"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <validation-provider :name="$t('lbl_age_short')">
                <h5 class for>{{ $t('lbl_age_short') }}</h5>
                <b-form-input v-model="customerInfo.age" :placeholder="$t('lbl_enter_age')" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider :name="$t('lbl_gender')">
                <h5 class for>{{ $t('lbl_gender') }}</h5>
                <div class="d-flex mt-1">
                  <b-form-radio
                    v-model="customerInfo.gender"
                    name="nam"
                    :value="1"
                  >{{ $t('lbl_male') }}</b-form-radio>
                  <b-form-radio
                    class="ml-1"
                    v-model="customerInfo.gender"
                    name="nu"
                    :value="2"
                  >{{ $t('lbl_female') }}</b-form-radio>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider :name="$t('lbl_address')">
                <h5 class for>{{$t('lbl_address')}}</h5>

                <b-form-input
                  v-model="customerInfo.address"
                  :placeholder="$t('lbl_enter_address')"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider :name="$t('lbl_notes')">
                <h5 class for>{{$t('lbl_notes')}}</h5>
                <b-form-input v-model="notes" :placeholder="$t('lbl_enter_notes')" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <h5 class for>{{ $t('lbl_select_product') }}</h5>
              <v-select
                @input="handleUpdateListReceiving"
                :placeholder="$t('lbl_select_product') "
                :options="products"
                label="name"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon icon="ChevronDownIcon" size="20" />
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col md="12 mt-2">
            <h4>{{ $t('lbl_list_products') }}</h4>
            <b-table
              :tbody-tr-class="checkNew"
              responsive
              :items="listReceiving"
              :fields="tableColumns"
              class="cs-table"
            >
              <template #cell(product_code)="data">
                <span>{{ `#${data.item. product_code}`}}</span>
              </template>
              <template #cell(amount)="data">
                <b-form-input type="number" v-model="data.item.amount" />
              </template>
              <template #cell(unit_id)="data">
                <span>{{ getUnit(data.item)}}</span>
              </template>
              <template #cell(expired_date)="data">
                <span>{{ formatExpiredDate(data.item)}}</span>
              </template>
              <template #cell(supplier)="data">
                <span>{{ getSupplierLabel(data.item)}}</span>
              </template>
              <template #cell(action)="data">
                <span class="cursor-pointer" @click="handleDeleteMedicine(data.item)">
                  <feather-icon icon="Trash2Icon" size="24" class="text-danger" />
                </span>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12" class="mt-3 d-flex align-items-center justify-content-end">
            <b-button
              @click="handleSubmit"
              :disabled="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
            >{{ $t(isUpdateSaling?'lbl_update_saling':'lbl_add_saling') }}</b-button>
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="handleBack"
              variant="outline-secondary"
            >{{ $t('lbl_exits') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import i18n from "../../../libs/i18n";

const formaterCurrency = new Intl.NumberFormat("en-US");

const tableColumns = [
  { key: "product_code", label: "SKU", sortable: false },
  {
    key: "name",
    label: i18n.t("lbl_product_name_short"),
    sortable: false
  },
  { key: "unit_id", label: i18n.t("lbl_units"), sortable: false },
  {
    key: "qty",
    label: i18n.t("lbl_qty"),
    sortable: false
  },
  {
    key: "amount",
    label: i18n.t("lbl_amount"),
    sortable: false,
    thStyle: { width: "15%" }
  },
  { key: "action", label: "", sortable: false, thStyle: { width: "2%" } }
];

export default {
  name: "AddSaling",
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      listReceiving: [],
      tableColumns,
      productSuppliers: [],
      supplierInfo: null,
      required,
      formaterCurrency,
      appUtils,
      inventories: [],
      inventoryId: null,
      customerInfo: {},
      salingDetail: {},
      notes: "",
      products: []
    };
  },
  computed: {
    isUpdateSaling() {
      return !!this.salingDetail?.id;
    }
  },
  watch: {
    $route() {
      this.getSalingDetail();
    }
  },
  created() {
    this.fetchSupplier();
    this.getSalingDetail();
  },
  methods: {
    totalPrice() {
      if (this.listReceiving.length > 0) {
        const arrRowsSum = this.listReceiving.map(x => {
          const realPrice = x.price_out * (1 + x.vat_out / 100);
          return this.formaterCurrencyToNumber(realPrice) * x.amount;
        });
        const total = arrRowsSum.reduce((a, b) => a + b, 0);
        return this.formaterCurrency.format(total);
      } else {
        return 0;
      }
    },
    createSaling() {
      this.$refs.addSallingValidation.validate().then(success => {
        if (success) {
        }
      });
    },
    async updateSaling() {
      this.$refs.addSallingValidation.validate().then(success => {
        if (success) {
        }
      });
    },
    handleUpdateListReceiving(value) {
      this.listReceiving.push({
        ...value,
        isNew: true
      });
    },
    checkNew(item, type) {
      if (!item || type !== "row") return;
      if (item.isNew) {
        setTimeout(() => {
          item.isNew = false;
        }, 1000);
        return "table-success";
      }
    },
    fetchSupplier() {},
    formaterCurrencyToNumber(value) {
      return Number(value?.toString().replace(/[^0-9.-]+/g, ""));
    },
    getActiveIngredient(data) {
      return data?.active_ingredient_info?.name || "";
    },
    getUnit(data) {
      return data?.unit_info?.name || "";
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name || "";
    },
    handleDeleteMedicine(medicine) {
      this.listReceiving = this.listReceiving.filter(
        item => item?.id !== medicine?.id
      );
    },

    getAge(birthday) {
      return (
        birthday &&
        (window.moment().diff(birthday, "years", false)
          ? `${window.moment().diff(birthday, "years", false)}`
          : "")
      );
    },
    getAddress(data) {
      let result = "";
      if (data.person_info?.address) {
        result += data.person_info?.address;
      }
      if (data.person_info?.address_district_name) {
        result = result + " " + data.person_info?.address_district_name;
      }
      if (data.person_info?.address_province_name) {
        result = result + " " + data.person_info?.address_province_name;
      }

      return result;
    },
    async getSalingDetail() {
      if (!this.$route.params.id) return;
    },

    handleSubmit() {
      this.isUpdateSaling ? this.updateSaling() : this.createSaling();
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handleGetCustomerInfo(event) {},

    formatMoment(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data?.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "01/";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "01/";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}

.is-new {
  background-color: rgba(29, 68, 177, 0.12);
}

// .cs-table table {
//   min-width: 1200px;
// }
</style>
